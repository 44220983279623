<template>
  <CommonDlg v-model:dlgVisible="visible" :cancel-btn="_cancelBtn" :confirm-btn="_confirmBtn" :title="title">
    <v-card-text>
      <div class="text-center">
        <v-icon class="fa-solid fa-exclamation-triangle text-warning" size="50" />

        <div class="text-h6 text-medium-emphasis ps-2 font-weight-bold mt-4">
          {{ subTitle }}
        </div>
        <p class="mt-4">
          {{ content }}
        </p>
      </div>
    </v-card-text>
  </CommonDlg>
</template>

<script setup>
const emit = defineEmits(['update:dlgVisible', 'deleteFn'])

const _cancelBtn = {
  name: '取消',
  action: () => {
    emit('update:dlgVisible', false)
  }
}

const _confirmBtn = {
  name: '刪除',
  action: () =>
    emit('deleteFn', props.data)
}

const visible = ref(false)

const props = defineProps({
  dlgVisible: Boolean,
  data: {
    type: Object,
    default: () => {}
  },
  subTitle: {
    type: String,
    default: '刪除資料?'
  },
  title: {
    type: String,
    default: ''
  },
  content: {
    type: String,
    default: '確定要刪除此筆資料嗎？'
  }
})
watch(() => props.dlgVisible, (val) => {
  visible.value = val
})
</script>
